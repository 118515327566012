var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mini-new-template" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dataset.show,
            "before-close": _vm.dialogCloseHandler,
            width: "860px",
            title: "新增模板",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dataset, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dialogForm",
              attrs: {
                "label-position": "top",
                model: _vm.form,
                rules: _vm.formRule,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择项目", prop: "projectId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请选择项目",
                        disabled: _vm.dataset.datas.evaluateId !== undefined,
                      },
                      on: { change: _vm.projectChange },
                      model: {
                        value: _vm.form.projectId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "projectId", $$v)
                        },
                        expression: "form.projectId",
                      },
                    },
                    _vm._l(_vm.projectList, function (item) {
                      return _c("el-option", {
                        key: item.projectId,
                        attrs: {
                          value: item.projectId,
                          label: item.projectName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择功能", prop: "functionId" } },
                [
                  _vm.dataset.datas.evaluateId
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择项目", disabled: "" },
                          model: {
                            value: _vm.form.functionId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "functionId", $$v)
                            },
                            expression: "form.functionId",
                          },
                        },
                        _vm._l(_vm.functionDiaplay, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      )
                    : _c("el-cascader", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          options: _vm.functionList,
                          props: { expandTrigger: "hover" },
                        },
                        model: {
                          value: _vm.form.functionId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "functionId", $$v)
                          },
                          expression: "form.functionId",
                        },
                      }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "gray-card" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "评价标题及分数",
                        prop: "evaluateConfig",
                      },
                    },
                    _vm._l(_vm.form.evaluateConfig, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "config-row",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-input", {
                            staticClass: "config-input",
                            attrs: { placeholder: "请输入分数评价标题" },
                            model: {
                              value: item.label,
                              callback: function ($$v) {
                                _vm.$set(item, "label", $$v)
                              },
                              expression: "item.label",
                            },
                          }),
                          _c("el-input-number", {
                            staticClass: "config-input",
                            attrs: {
                              placeholder: "请输入分数",
                              "controls-position": "right",
                              min: 0,
                              max: 5,
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.form.evaluateConfig.length > 1,
                                  expression: "form.evaluateConfig.length > 1",
                                },
                              ],
                              staticClass: "delele-button",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteSingleConfig(index)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "add-line" }, [
                    _c(
                      "span",
                      {
                        staticClass: "add-inner",
                        on: { click: _vm.addNewConfig },
                      },
                      [
                        _c("i", { staticClass: "el-icon-plus" }),
                        _vm._v("\n            添加\n          "),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "button-line" },
            [
              _c(
                "r-button",
                {
                  attrs: { type: "cancel" },
                  on: { click: _vm.dialogCloseHandler },
                },
                [_vm._v("取消")]
              ),
              _c(
                "r-button",
                {
                  attrs: { plain: "", loading: _vm.submitLoading },
                  on: { click: _vm.submitHandler },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }