<template>
  <div class="app-container mini-evaluation-management">
    <div class="button-line">
      <r-button plain @click="addNewConfig">新增模板</r-button>
      <r-button @click="initDatas">刷新</r-button>
    </div>
    <div class="table-container">
      <FinalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <NewTemplateDialog
      v-if="newConfigDialog.show"
      :projectList="projectList"
      :dataset="newConfigDialog"
      @close="dialogCloseHandler"
    />
  </div>
</template>
<script>
import FinalTable from "@/components/FinalTable";
import {
  getEvaluationList,
  functionEvaluationDelete,
} from "@/api/ruge/lego/config";
import NewTemplateDialog from "./components/newTemplateDialog.vue";
import { getProjectList } from "@/api/business/base/tenant/map";

export default {
  name: "mini-evaluation-management",
  components: {
    FinalTable,
    NewTemplateDialog,
  },
  data() {
    return {
      loadingFlag: false,
      projectList: [],
      newConfigDialog: {
        show: false,
        datas: {},
      },
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        header: [
          { prop: "projectId", label: "项目", width: "" },
          { prop: "functionName", label: "功能", width: "" },
          { prop: "creationUserName", label: "创建人", width: "" },
          { prop: "creationDate", label: "创建时间", width: "" },
          { prop: "updateUserName", label: "修改人", width: "" },
          { prop: "lastUpdateDate", label: "修改时间", width: "" },
          { prop: "operation", label: "操作", width: "120" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          projectId: {
            type: "selector",
            label: "项目",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择项目",
            optionList: [],
          },
        },
        // 表格内容配置
        detailConfig: {
          projectId: {
            type: "otherParam",
            paramName: "projectName",
          },
          creationDate: {
            type: "dateFormat",
          },
          lastUpdateDate: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-document-copy",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      filterObj: {
        current: 1,
        rowCount: 10,
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.initDatas();
  },
  methods: {
    dialogCloseHandler(flag) {
      this.newConfigDialog.show = false;
      flag === true && this.initDatas();
    },
    addNewConfig() {
      this.newConfigDialog.show = true;
      this.newConfigDialog.datas = {};
    },
    initDatas() {
      getProjectList().then((res) => {
        this.projectList = res || [];
        this.dataset.searchLineConfig.projectId.optionList = res.map((item) => {
          return {
            value: item.projectId,
            label: item.projectName,
          };
        });
      });
      this.loadingFlag = true;
      getEvaluationList(this.filterObj)
        .then((res) => {
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.filterObj.current = 1;
        this.filterObj = { ...this.filterObj, ...datas.params };
        this.initDatas();
      } else if (datas.type === "paginationChange") {
        this.filterObj.current = datas.params.current.page;
        this.filterObj.rowCount = datas.params.current.limit;
        this.initDatas();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "delete":
            this.deleteConfig(datas.row);
            break;
          case "edit":
            this.editConfig(datas.row);
            break;
        }
      }
    },
    editConfig(rowDatas) {
      this.newConfigDialog.datas = rowDatas;
      this.newConfigDialog.show = true;
    },
    async deleteConfig(rowDatas) {
      await this.$confirm(`确认删除该配置？`, this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: this.$t("commons.cancel"),
        type: "warning",
      });
      functionEvaluationDelete(rowDatas.evaluateId).then(() => {
        this.$message.success("删除成功！");
        this.initDatas();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.mini-evaluation-management {
  .button-line {
    margin-bottom: 10px;
  }
}
</style>


















































