<template>
  <div class="mini-new-template">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="dialogCloseHandler"
      width="860px"
      title="新增模板"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-form
        label-position="top"
        :model="form"
        ref="dialogForm"
        :rules="formRule"
      >
        <el-form-item label="选择项目" prop="projectId">
          <el-select
            style="width: 100%"
            v-model="form.projectId"
            placeholder="请选择项目"
            @change="projectChange"
            :disabled="dataset.datas.evaluateId !== undefined"
          >
            <el-option
              v-for="item in projectList"
              :value="item.projectId"
              :label="item.projectName"
              :key="item.projectId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择功能" prop="functionId">
          <el-select
            v-if="dataset.datas.evaluateId"
            style="width: 100%"
            v-model="form.functionId"
            placeholder="请选择项目"
            disabled
          >
            <el-option
              v-for="item in functionDiaplay"
              :value="item.value"
              :label="item.label"
              :key="item.value"
            ></el-option>
          </el-select>
          <el-cascader
            v-else
            style="width: 100%"
            v-model="form.functionId"
            :options="functionList"
            :props="{ expandTrigger: 'hover' }"
          >
          </el-cascader>
        </el-form-item>
        <div class="gray-card">
          <el-form-item label="评价标题及分数" prop="evaluateConfig">
            <div
              class="config-row"
              :gutter="20"
              v-for="(item, index) in form.evaluateConfig"
              :key="index"
            >
              <el-input
                class="config-input"
                placeholder="请输入分数评价标题"
                v-model="item.label"
              ></el-input>
              <el-input-number
                placeholder="请输入分数"
                class="config-input"
                v-model="item.value"
                controls-position="right"
                :min="0"
                :max="5"
              ></el-input-number>
              <el-button
                v-show="form.evaluateConfig.length > 1"
                type="text"
                class="delele-button"
                @click="deleteSingleConfig(index)"
                >删除</el-button
              >
            </div>
          </el-form-item>
          <div class="add-line">
            <span class="add-inner" @click="addNewConfig">
              <i class="el-icon-plus"></i>
              添加
            </span>
          </div>
        </div>
      </el-form>
      <div class="button-line">
        <r-button type="cancel" @click="dialogCloseHandler">取消</r-button>
        <r-button plain @click="submitHandler" :loading="submitLoading"
          >保存</r-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getEvaluationFunction,
  functionEvaluationSave,
} from "@/api/ruge/lego/config";

export default {
  name: "mini-new-template",
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
        };
      },
    },
    projectList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      functionDiaplay: [],
      submitLoading: false,
      functionList: [],
      form: {
        projectId: null,
        functionId: [],
        evaluateConfig: [{ label: null, value: 0 }],
      },
      formRule: {
        projectId: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        functionId: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        evaluateConfig: [
          {
            required: true,
            validator: (a, b, callback) => {
              for (let item of this.form.evaluateConfig) {
                const { value, label } = item;
                console.log("item", item);
                if (value === undefined) {
                  callback(new Error("请填写分数"));
                  break;
                }
                if (!label) {
                  callback(new Error("请填写分数评价标题"));
                  break;
                }
              }
              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    projectChange(projectId) {
      this.form.functionId = [];
      getEvaluationFunction({ projectId }).then((res) => {
        this.functionList = res.map((item) => {
          return {
            label: item.functionTypeName,
            value: item.functionType,
            children: item.functionList.map((single) => {
              return {
                label: single.functionName,
                value: single.functionId,
              };
            }),
          };
        });
      });
    },
    deleteSingleConfig(index) {
      this.form.evaluateConfig.splice(index, 1);
    },
    addNewConfig() {
      this.form.evaluateConfig.push({
        label: null,
        value: 0,
      });
    },
    initDatas() {
      if (this.dataset.datas && this.dataset.datas.evaluateId) {
        const {
          evaluateConfig,
          projectId,
          evaluateId,
          functionId,
          functionName,
        } = this.dataset.datas;
        this.form.projectId = projectId;
        this.functionDiaplay = [
          {
            label: functionName,
            value: functionId,
          },
        ];
        this.form.functionId = functionId;
        this.form.evaluateId = evaluateId;
        this.form.evaluateConfig = JSON.parse(evaluateConfig);
      }
    },
    dialogCloseHandler(flag) {
      this.$emit("close", flag);
    },
    async submitHandler() {
      await this.$refs.dialogForm.validate();
      this.submitLoading = true;
      const params = { ...this.form };
      params.evaluateConfig = JSON.stringify(params.evaluateConfig);
      if (!this.dataset.datas.evaluateId) {
        params.functionId = params.functionId[1];
      }
      console.log("params", params);
      functionEvaluationSave(params).then(() => {
        this.$message.success("保存成功！");
        this.$emit("close", true);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.mini-new-template {
  .gray-card {
    background: #f5f7f9;
    padding: 10px;
    width: calc(100% + 20px);
    margin-left: -10px;
    padding-bottom: 25px;
    border-radius: 10px;
    margin-top: 25px;
  }
  .config-row {
    .config-input {
      display: inline-block;
      margin-right: 16px;
      width: 369px;
    }
    .delele-button {
      color: #ff0000;
      display: inline-block;
    }
  }
  .config-row + .config-row {
    margin-top: 20px;
  }
  .add-line {
    text-align: center;
    margin-top: 20px;
    .add-inner {
      color: #2a61ff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      .el-icon-plus {
        font-weight: bold;
        font-size: 16px;
        margin-right: 2px;
      }
    }
  }
  .button-line {
    text-align: right;
    margin: 20px 0;
  }
}
</style>